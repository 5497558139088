// @import Dependencies
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Button, Typography, useMediaQuery } from '@mui/material';

// @import Components
import { WriterlyTabs } from '@shared/Tabs/Tabs';

// @import Reducers
import { logout } from '@redux/slices/auth';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const WriterlyNavBar = ({
  children,
  tabs,
  title,
  extraContent,
  extraButtons,
  handleClickTab,
  navExtraStyle = 'w-full',
  titleClasses = '',
  titleStyles,
}) => {
  const { t } = useTranslation();
  const location = useLocation()?.pathname;
  const isAtCreate = !location.includes('templates');
  const smallScreen = useMediaQuery('(max-width: 834px)');
  const dispatch = useDispatch();

  return (
    <nav className="flex flex-col">
      <div
        className={`relative flex flex-col items-center md:pr-20 md:pl-14 xl:p-0 xl:flex-row ${navExtraStyle}`}
      >
        <div
          className={`w-full ${
            isAtCreate ? 'xl:w-40' : 'xl:w-96'
          } xl:w-fit xl:mr-9`}
          title={title}
        >
          <Typography
            noWrap={!smallScreen}
            variant={'h2'}
            align={smallScreen ? 'center' : 'left'}
            className={`capitalize w-full xl:w-fit lg:max-w-lg xl:max-w-xl ${titleClasses}`}
            data-testid="navbar-PageTitle"
            sx={titleStyles}
          >
            {title}
          </Typography>
        </div>
        <div
          className={`grow grid grid-cols-12 place-content-center justify-items-center lg:justify-items-end lg:w-fit z-10 ${
            tabs?.length ? 'gap-4' : ''
          }`}
        >
          {tabs && !!tabs.length && (
            <div className="col-span-12 lg:col-span-9 lg:flex justify-center items-center mt-8 lg:mt-3">
              <div className="lg:max-h-[60px]">
                {children && (
                  <WriterlyTabs handleClickTab={handleClickTab} tabsData={tabs}>
                    {children}
                  </WriterlyTabs>
                )}
                {!children && (
                  <WriterlyTabs
                    handleClickTab={handleClickTab}
                    tabsData={tabs}
                  />
                )}
              </div>
            </div>
          )}

          <div
            className={`${
              !tabs?.length
                ? 'xl:col-span-12 justify-end'
                : 'xl:col-span-3 justify-center'
            } col-span-12 flex gap-3 md:gap-8 items-center justify-items-center mt-4`}
            style={{
              marginTop: smallScreen && '4px',
              marginLeft: '10px',
              marginRight: '10px',
            }}
          >
            {extraButtons}
          </div>
        </div>
        <Button
          className="ml-auto max-w-32 px-6"
          variant="outlined"
          onClick={() => {
            dispatch(logout());
          }}
        >
          {t('generalActions.logout')}
        </Button>
      </div>
      {extraContent}
    </nav>
  );
};

WriterlyNavBar.propTypes = {
  title: PropTypes.string.isRequired,
  sideContent: PropTypes.any,
  children: PropTypes.node,
  extraContent: PropTypes.node,
  extraButtons: PropTypes.node,
  handleClickTab: PropTypes.func,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
      id: PropTypes.string,
    })
  ),
  navExtraStyle: PropTypes.string,
  titleClasses: PropTypes.string,
  titleStyles: PropTypes.any,
};
