import { makeRequest } from '@api/client';

const getOptimizationLogStatisticsCardsAPI = async (id) => {
  return await makeRequest(`dashboard/statistics/cards/${id}`, {
    method: 'GET',
  });
};

const getOptimizationHistoryLogAPI = async ({ id, params = {} }) => {
  const queryParams = new URLSearchParams(params).toString();
  return await makeRequest(
    `dashboard/optimization/history-log/${id}?${queryParams}`,
    {
      method: 'GET',
    }
  );
};

const getOptimizationLogProductDetailsAPI = async ({ id, params }) => {
  const queryParams = new URLSearchParams(params).toString();
  return await makeRequest(
    `optimization-history-logs-details/${id}?${queryParams}`,
    {
      method: 'GET',
    }
  );
};

const getOptimizationLogProductMetricsAPI = async ({ shopId, rowId }) => {
  return await makeRequest(
    `optimization-history-logs-metrics/${shopId}/${rowId}`,
    {
      method: 'GET',
    }
  );
};

const getOtherRoundsOptionsAPI = async ({ rowId, params }) => {
  const queryParams = new URLSearchParams(params).toString();
  return await makeRequest(
    `optimization-log/previous-rounds/${rowId}?${queryParams}`,
    {
      method: 'GET',
    }
  );
};

const restoreSuperRowAPI = async ({ body }) => {
  return await makeRequest(`optimization-log/restore-super-row`, {
    method: 'POST',
    body,
  });
};

const getOptimizationLatestReviewingJobAPI = async ({ shopId }) => {
  return await makeRequest(`autopilot/latest-reviewing-job/${shopId}`, {
    method: 'GET',
  });
};

const updatePdpStatusAPI = async (body) => {
  return await makeRequest(`autopilot-rows/set-pdp-status`, {
    method: 'PATCH',
    body,
  });
};

const newSampleJobAPI = async ({ shopId }) => {
  return await makeRequest(`autopilot-job/new-sample/${shopId}`, {
    method: 'POST',
  });
};

const rerunSamplesAPI = async (body) => {
  return await makeRequest('sample-rerun-post', {
    method: 'POST',
    body,
  });
};

const getRerunSampleAPI = async ({ id }) => {
  return await makeRequest(`sample-rerun-get/${id}`, {
    method: 'GET',
  });
};

const getSampleApprovalsAPI = async (body) => {
  return await makeRequest(
    `autopilot/sample-rows/${body.shopId}?page=${body?.page || 1}&page_size=${
      body?.pageSize || 10
    }`,
    {
      method: 'GET',
    }
  );
};

export {
  getOptimizationLogStatisticsCardsAPI,
  getOptimizationHistoryLogAPI,
  getOptimizationLogProductDetailsAPI,
  getOtherRoundsOptionsAPI,
  getOptimizationLogProductMetricsAPI,
  restoreSuperRowAPI,
  getOptimizationLatestReviewingJobAPI,
  updatePdpStatusAPI,
  newSampleJobAPI,
  rerunSamplesAPI,
  getRerunSampleAPI,
  getSampleApprovalsAPI,
};
