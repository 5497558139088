export function formatNumberWithCommas(number) {
  if (!number) return '0';
  return number.toLocaleString('en-US');
}

export const formatPercentageValue = (value) => {
  if (!value) return '0';
  if (validateZeroDifference(value)) return '0';
  const formattedValue = value.toFixed(0);
  return Number(formattedValue).toLocaleString();
};

export const validateZeroDifference = (value) => {
  return (value >= 0 && value < 1) || (value > -1 && value < 0);
};
