import React from 'react';
import { Card, Typography, Box, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WriterlyIcon } from '@shared/Icon/Icon';
import { TitleWithTooltip } from '@shared/Text/TitleWithTooltip.jsx';

const MetricsGrid = ({ title, data, valueRenderer }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const MetricWidget = ({ item }) => (
    <Card
      sx={{
        backgroundColor: theme.palette.sidebar.background,
      }}
      className="text-center rounded-3xl flex flex-col items-center justify-center gap-2 p-4"
    >
      <Box className="flex items-center justify-center gap-1">
        {item?.icon && (
          <WriterlyIcon
            iconName={item.icon.name}
            width={item.icon.width}
            height={item.icon.height}
            className={item.icon.className}
            stroke={theme.palette.text.primary}
          />
        )}
        <TitleWithTooltip
          titleClassName="font-semibold text-lg truncate"
          title={item.title}
          tooltipText={item.tooltip}
        />
      </Box>
      <Typography variant="h4" className="font-bold">
        {valueRenderer ? valueRenderer(item.value) : item.value}
      </Typography>
    </Card>
  );

  return (
    <div className="mx-auto mt-10">
      <Typography variant="h4" className="font-bold self-start mb-5">
        {t(title)}
      </Typography>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-6">
        {data.map((item) => (
          <MetricWidget key={item.title} item={item} />
        ))}
      </div>
    </div>
  );
};

export default MetricsGrid;
