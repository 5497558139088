import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { Box, Fade, Tab, Tabs, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const CustomTabs = ({ children, handleClickTab }) => {
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const tabOrientation = smallScreen ? 'vertical' : 'horizontal';
  const tabVariant = smallScreen ? 'standard' : 'scrollable';
  const location = useLocation();
  const theme = useTheme();

  const defaultValue = children.findIndex(
    (child) =>
      location.pathname === child.props.to ||
      location.pathname.includes(child.props.to)
  );

  const [value, setValue] = useState(
    defaultValue !== -1
      ? children.findIndex(
          (child) =>
            location.pathname === child.props.to ||
            location.pathname.includes(child.props.to)
        )
      : 0
  );

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          variant={tabVariant}
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable tabs"
          orientation={tabOrientation}
          sx={{
            '.MuiTabs-indicator': {
              backgroundColor: theme.palette.info.main,
            },
          }}
        >
          {React.Children.map(children, (child, index) => (
            <Tab
              key={index}
              sx={{
                color: `${theme.palette.text.primary} !important`,
              }}
              label={child.props.label}
              component={!handleClickTab ? Link : null}
              className={`px-0 ${
                index === 0 ? 'mr-4' : 'mx-4'
              } py-1 font-bold text-black ${
                value === index ? 'opacity-100' : 'opacity-60'
              }`}
              to={child.props.to}
              selected={value === index}
              onClick={() => {
                handleClickTab &&
                  child.props.id &&
                  handleClickTab(child.props.id);
              }}
              data-testid={`customTab${index}`}
            />
          ))}
        </Tabs>
      </Box>
      {React.Children.map(children, (child, index) => (
        <Fade
          unmountOnExit={true}
          timeout={500}
          in={value === index}
          className="mt-10 md:ml-7"
        >
          <Box>{React.cloneElement(child, { value, index })}</Box>
        </Fade>
      ))}
    </Box>
  );
};

CustomTabs.propTypes = {
  extraButtons: PropTypes.any,
  children: PropTypes.node,
  handleClickTab: PropTypes.func,
};

export default CustomTabs;
