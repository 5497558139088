// @import Dependencies
import { useTranslation } from 'react-i18next';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import dayjs from 'dayjs';
// @import Components
import { Box, Typography, useTheme } from '@mui/material';
// @import Utils
import { hexToRgbaWithOpacity } from '@utils/index';
import { formatNumberWithCommas } from '@utils/number';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const CogsMeasureGraph = ({ graphData }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  // Arbitrary colors for both modes (not necessary to be in theme config)
  const availableDataKeys = {
    overall_openai_cost_per_request: '#fe777b',
    aws_cost_per_request_average: '#ff9900',
    semrush_cost_per_request_average: '#ea4335',
    total_cost_per_request_average: '#b899ff',
    openai_cost: '#34a853',
    aws_cost: '#4285f4',
    semrush_cost: '#fbbc04',
  };

  const graphOptions = {
    plugins: {
      legend: {
        display: true,
        onClick: (e, legendItem, legend) => {
          const chart = legend.chart;
          const datasetIndex = legendItem.datasetIndex;
          chart.data.datasets[datasetIndex].hidden =
            !chart.data.datasets[datasetIndex].hidden;
          chart.update();
        },
      },
    },
    maintainAspectRatio: true,
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        grid: {
          color: hexToRgbaWithOpacity(theme.palette.text.primary, 0.2),
        },
        ticks: {
          color: theme.palette.text.primary,
        },
        title: {
          display: true,
          text: t('adminDashboard.cogsMeasure.currency'),
          color: theme.palette.text.primary,
          padding: 10,
          font: {
            size: 16,
            weight: '400',
          },
        },
        beginAtZero: true,
        stacked: true,
      },
    },
  };

  const totalLabelsPlugin = {
    id: 'totalLabels',
    afterDatasetsDraw(chart) {
      const {
        ctx,
        scales: { x, y },
      } = chart;
      chart.data.labels.forEach((label, index) => {
        let total = 0;
        chart.data.datasets
          .filter((s) => !s.hidden)
          .forEach((dataset) => {
            total += parseFloat(dataset.data[index].replace(/,/g, '') || 0);
          });
        const totalParsed = formatNumberWithCommas(total);
        const xPos = x.getPixelForValue(index);
        const yPos = y.getPixelForValue(total);
        ctx.save();
        ctx.fillStyle = theme.palette.text.primary;
        ctx.font = 'bold 12px Arial';
        ctx.textAlign = 'center';
        ctx.fillText(`$ ${totalParsed}`, xPos, yPos - 10);
        ctx.restore();
      });
    },
  };

  const monthlyKeysToAvoid = [
    'total_number_of_requests',
    'monthly_total_cost',
    'aws_cost_per_request_average',
    'semrush_cost_per_request_average',
    'total_cost_per_request_average',
    'overall_openai_cost_per_request',
  ];
  const monthlyKeys = Object.keys(graphData.monthly_summaries);
  const labels = monthlyKeys
    .map((key) => dayjs(`${key}-01`).format('MMM YYYY'))
    .sort((a, b) => a - b);
  const dataSets = Object.keys(graphData.monthly_summaries[monthlyKeys[0]])
    .filter((key) => !monthlyKeysToAvoid.includes(key))
    .map((key) => ({
      label: t(`adminDashboard.cogsMeasure.bars.${key}`),
      data: monthlyKeys.map((_v) =>
        formatNumberWithCommas(graphData.monthly_summaries[_v][key]).replace(
          /,/g,
          ''
        )
      ),
      backgroundColor: availableDataKeys[key],
    }));

  const data = {
    labels,
    datasets: dataSets,
  };

  return (
    <Box className="px-[5%] py-6 w-full">
      <Typography variant="h4" className="font-bold mb-10 self-start">
        {t('adminDashboard.cogsMeasure.title')}
      </Typography>
      <Box className="justify-center flex">
        <Box className="w-full 2xl:w-3/4">
          <Bar
            options={graphOptions}
            data={data}
            plugins={[totalLabelsPlugin]}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CogsMeasureGraph;
