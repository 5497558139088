import PropTypes from 'prop-types';
import { Button, ButtonGroup, Tooltip, useTheme } from '@mui/material';

export const CustomButtonGroup = ({
  value,
  disabled = false,
  ariaLabel,
  className = '',
  buttons = [],
}) => {
  const theme = useTheme();

  return (
    <ButtonGroup
      value={value}
      aria-label={ariaLabel}
      className={className}
      disabled={disabled}
    >
      {buttons.map((button) => {
        return (
          <Tooltip
            key={button.id}
            title={button.hover}
            PopperProps={{
              sx: {
                '& .MuiTooltip-tooltip': {
                  backgroundColor: `${theme.palette.background.paper} !important`,
                  color: theme.palette.text.primary,
                  border: `1px solid ${theme.palette.text.primary}`,
                  borderRadius: '8px',
                  maxWidth: 'none',
                  whiteSpace: 'normal',
                },
              },
            }}
          >
            <Button
              key={button.id}
              aria-label={button.ariaLabel}
              value={button.id}
              variant={
                value === button.id || button.id === button.value
                  ? 'contained'
                  : 'outlined'
              }
              className={`${className} ${
                value === button.id ||
                (button.id === button.value && 'rounded scale-105 z-10')
              }`}
              style={
                value === button.id || button.id === button.value
                  ? {}
                  : { borderColor: theme.palette.buttons.border }
              }
              onClick={button.handleOnClick}
            >
              {button.label}
            </Button>
          </Tooltip>
        );
      })}
    </ButtonGroup>
  );
};

CustomButtonGroup.propTypes = {
  value: PropTypes.any,
  disabled: PropTypes.bool,
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      label: PropTypes.string,
      ariaLabel: PropTypes.string,
      handleOnClick: PropTypes.func,
      className: PropTypes.string,
      hover: PropTypes.node,
    })
  ),
};
