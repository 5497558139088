import { Box, Grid, Card, CardContent, Typography } from '@mui/material';
import { CustomButtonGroup } from '@shared/ButtonGroup/ButtonGroup.jsx';
import dayjs from 'dayjs';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { TitleWithTooltip } from '@shared/Text/TitleWithTooltip.jsx';

const IntervalMetrics = ({
  intervalMetrics,
  selectedInterval,
  setSelectedInterval,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const formatDate = (dateString) => {
    const date = dayjs(dateString, 'YYYY-MM-DD');
    return date.isValid() ? date.format('MMM DD, YYYY') : dateString;
  };

  return (
    <Box className="py-6" sx={{ width: '100%' }}>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Card
            sx={{
              backgroundColor: theme.palette.sidebar.background,
              borderRadius: 1,
              textAlign: 'center',
              padding: 0,
            }}
          >
            <Box
              className="p-4 flex items-center justify-between gap-2"
              style={{
                backgroundColor: theme.palette.metrics.card.background.main,
              }}
            >
              <TitleWithTooltip
                titleClassName="text-xl font-semibold"
                title={t('adminDashboard.optimizedPDPs.title')}
                tooltipText={t('adminDashboard.optimizedPDPs.tooltip', {
                  from: formatDate(intervalMetrics[selectedInterval].date_from),
                  to: formatDate(intervalMetrics[selectedInterval].date_to),
                })}
              />
              <CustomButtonGroup
                className="whitespace-nowrap"
                value={selectedInterval}
                ariaLabel="Interval selection"
                buttons={intervalMetrics
                  .filter((interval) =>
                    ['30 Days', '90 Days', '6 Months'].includes(
                      interval.interval
                    )
                  )
                  .map((interval, index) => ({
                    id: index,
                    label: interval.interval,
                    ariaLabel: `Select ${interval.interval}`,
                    handleOnClick: () => setSelectedInterval(index),
                    hover: t('dashboard.metrics.dates', {
                      from: interval.date_from
                        ? formatDate(interval.date_from)
                        : '',
                      to: formatDate(interval.date_to),
                    }),
                  }))}
              />
            </Box>
            <CardContent>
              <Typography
                variant="h4"
                sx={{
                  color: theme.palette.text.primary,
                  fontWeight: 'bold',
                }}
              >
                {intervalMetrics[
                  selectedInterval
                ]?.optimized_pdp_count?.toLocaleString() || '0'}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: theme.palette.text.primary, marginTop: 1 }}
              >
                {t('adminDashboard.dateRange', {
                  from: formatDate(intervalMetrics[selectedInterval].date_from),
                  to: formatDate(intervalMetrics[selectedInterval].date_to),
                })}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default IntervalMetrics;
