import MetricsGrid from '@shared/AdminDashboard/MetricsGrid.jsx';

const PDPCostMetrics = ({ pdpCosts }) => {
  const data = pdpCosts.map(({ amount, ...rest }) => ({
    value: amount,
    ...rest,
  }));

  const valueRenderer = (value) => `$${value}`;

  return (
    <MetricsGrid
      title="adminDashboard.pdpCostMetrics.title"
      data={data}
      valueRenderer={valueRenderer}
    />
  );
};

export default PDPCostMetrics;
