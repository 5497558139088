// @import Dependencies
import PropTypes from 'prop-types';

// @import Components
import { useTheme } from '@mui/material';

export const NavbarHeader = ({ children, className = '' }) => {
  const theme = useTheme();
  return (
    <header
      className={`md:rounded-bl-3xl mb-2 ${className}`}
      style={{ background: theme.palette.sidebar.background }}
    >
      {children}
    </header>
  );
};

NavbarHeader.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
