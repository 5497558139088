import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import sidebarData from '@data/sidebar-data.json';
import {
  getCustomerPortalLink,
  getCustomerPortalLinkCheckout,
  getPlansAPI,
} from '@api/stripe';
import {
  userReportBug,
  userRequestIntegration,
  userRequestUpgrade,
} from '@api/user';

// @import Utilities
import { nomenclatureSnack } from '@utils/nomenclature';
import { orderedListById } from '@utils/sortFilter';

// @import Paths
import { authPaths } from '@routes/paths';

export const initialState = {
  sideBarOpen: true,
  sideBarItemsClicked: [],
  sideBarMenu: sidebarData,
  sideBarCounters: {
    optimizeAssets: 0,
  },
  hiddenSideBarIcons: false,
  regenerateAllRejectedModal: false,
  reportBugModal: false,
  inviteMemberModal: false,
  changePasswordModal: false,
  requestPricingModal: false,
  mobileMenuOpen: false,
  accessDeniedModal: false,
  reIndexingModal: false,
  requestUpgradeModal: false,
  requestIntegrationModal: false,
  accessKey: '',
  pushToCMSModal: false,
  pushToCMSConfirmationModal: false,
  setupAutomaticIndexingModal: false,
  integrationConnectedModal: false,
  brandGuidelinesUploadModal: false,
  activityLogModalOpen: false,
  undoContentUpdateModalOpen: false,
  bulkJobTab: 1,
  autopilotJobTab: 0,
  loading: false,
  onboarding: {
    noContentPushed: false,
    shopId: null,
  },
  keyImprovementsModal: false,
};

export const goToStripeCustomerPortal = createAsyncThunk(
  'navigation/goToStripeCustomerPortal',
  async (body, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await getCustomerPortalLink(body);
    if (isSuccessful) {
      return { ...data, statusKey };
    }
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const reportBug = createAsyncThunk(
  'navigation/reportBug',
  async (body, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await userReportBug(body);
    if (isSuccessful) {
      return { ...data, statusKey };
    }
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const requestUpgrade = createAsyncThunk(
  'navigation/requestUpgrade',
  async (body, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await userRequestUpgrade(body);
    if (isSuccessful) {
      return { ...data, statusKey };
    }
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const requestIntegration = createAsyncThunk(
  'navigation/requestIntegration',
  async (body, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await userRequestIntegration(
      body
    );
    if (isSuccessful) return { ...data, statusKey };
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const goToCustomerPortalLinkCheckout = createAsyncThunk(
  'navigation/goToCustomerPortalLinkCheckout',
  async (body, thunkAPI) => {
    const { data, isSuccessful, statusKey } =
      await getCustomerPortalLinkCheckout(body);
    if (isSuccessful) {
      return { ...data, statusKey };
    }
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const getStripePlans = createAsyncThunk(
  'navigation/getStripePlans',
  async (body, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await getPlansAPI();
    if (isSuccessful) {
      return { data, statusKey };
    }
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

// ------------------THUNKS-------------
export const sharedExtraReducers = (builder) => {
  builder
    .addCase(goToStripeCustomerPortal.pending, (state) => {
      state.loading = true;
    })
    .addCase(goToStripeCustomerPortal.fulfilled, (state, action) => {
      const data = action.payload;
      state.loading = false;
      nomenclatureSnack({
        type: 'success',
        message: data?.statusKey,
      });
      if (data?.portal_url) {
        window.location.href = action.payload?.portal_url;
      } else {
        window.history.pushState(null, '', authPaths.subscriptionPortal);
        window.location.reload();
      }
    })
    .addCase(goToStripeCustomerPortal.rejected, (state, action) => {
      state.loading = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })

    // Report Bug
    .addCase(reportBug.pending, (state) => {
      state.loading = true;
    })
    .addCase(reportBug.rejected, (state, action) => {
      state.loading = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(reportBug.fulfilled, (state, action) => {
      state.loading = false;
      nomenclatureSnack({
        type: 'success',
        message: action?.payload?.statusKey,
      });
    })

    .addCase(requestUpgrade.pending, (state) => {
      state.loading = true;
    })
    .addCase(requestUpgrade.rejected, (state, action) => {
      state.loading = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(requestUpgrade.fulfilled, (state, action) => {
      state.loading = false;
      nomenclatureSnack({
        type: 'success',
        message: action?.payload?.statusKey,
      });
    })

    .addCase(requestIntegration.pending, (state) => {
      state.loading = true;
    })
    .addCase(requestIntegration.rejected, (state, action) => {
      state.loading = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(requestIntegration.fulfilled, (state, action) => {
      state.loading = false;
      nomenclatureSnack({
        type: 'success',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(goToCustomerPortalLinkCheckout.pending, (state) => {
      state.loading = true;
    })
    .addCase(goToCustomerPortalLinkCheckout.fulfilled, (state, action) => {
      state.loading = false;
      nomenclatureSnack({
        type: 'success',
        message: action?.payload?.statusKey,
      });
      setTimeout(() => {
        window.location.href = action.payload?.portal_url;
      }, 2000);
    })
    .addCase(goToCustomerPortalLinkCheckout.rejected, (state, action) => {
      state.loading = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(getStripePlans.pending, (state) => {
      state.loading = true;
    })
    .addCase(getStripePlans.fulfilled, (state) => {
      state.loading = false;
    })
    .addCase(getStripePlans.rejected, (state, action) => {
      state.loading = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    });
};

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  extraReducers: sharedExtraReducers,
  reducers: {
    toggleSideBar: (state, action) => {
      state.sideBarOpen = action.payload;
      if (!state.sideBarOpen) {
        state.sideBarItemsClicked = [];
      }
    },
    toggleSideBarItem: (state, action) => {
      const { id } = action.payload;
      state.sideBarItemsClicked = state.sideBarItemsClicked.find(
        (item) => item === id
      )
        ? [...state.sideBarItemsClicked].filter((item) => item !== id)
        : [...state.sideBarItemsClicked, id];
    },
    toggleRegenerateAllRejectedModal: (state, action) => {
      state.regenerateAllRejectedModal = action.payload;
    },
    toggleInviteMemberModal: (state, action) => {
      state.inviteMemberModal = action.payload;
    },
    toggleReIndexingModal: (state, action) => {
      state.reIndexingModal = action.payload;
    },
    toggleReportBugModal: (state, action) => {
      state.reportBugModal = action.payload;
    },
    toggleChangePasswordModal: (state, action) => {
      state.changePasswordModal = action.payload;
    },
    toggleRequestPricingModal: (state, action) => {
      state.requestPricingModal = action.payload;
    },
    toggleMobileMenuOpen: (state, action) => {
      state.mobileMenuOpen = action.payload;
    },
    toggleAccessDeniedModal: (state, action) => {
      const { titleKey, isVisible } = action.payload;
      if (titleKey) state.accessKey = titleKey;
      state.accessDeniedModal = isVisible;
    },
    toggleRequestUpgradeModal: (state, action) => {
      state.requestUpgradeModal = action.payload;
    },
    toggleRequestIntegrationModal: (state, action) => {
      state.requestIntegrationModal = action.payload;
    },
    setAccessKey: (state, action) => {
      state.accessKey = action.payload;
    },
    togglePushToCMSModal: (state, action) => {
      state.pushToCMSModal = action.payload;
    },
    togglePushToCMSConfirmationModal: (state, action) => {
      state.pushToCMSConfirmationModal = action.payload;
    },
    toggleSetupAutomaticIndexingModal: (state, action) => {
      state.setupAutomaticIndexingModal = action.payload;
    },
    toggleBrandGuidelinesUploadModal: (state, action) => {
      state.brandGuidelinesUploadModal = action.payload;
    },
    toggleIntegrationConnectedModal: (state, action) => {
      state.integrationConnectedModal = action.payload;
    },
    toggleActivityLogModal: (state, action) => {
      state.activityLogModalOpen = action.payload;
    },
    toggleUndoContentUpdateModal: (state, action) => {
      state.undoContentUpdateModalOpen = action.payload;
    },
    toggleKeyImprovementsModal: (state, action) => {
      state.keyImprovementsModal = action.payload;
    },
    setBulkJobTab: (state, action) => {
      state.bulkJobTab = action.payload;
    },
    setAutopilotJobTab: (state, action) => {
      state.autopilotJobTab = action.payload;
    },
    setOptimizeAssetsCounter: (state, action) => {
      let jobs = action?.payload?.jobs;
      let counter = 0;
      if (action?.payload?.isGSC && Array.isArray(jobs) && jobs.length) {
        jobs = jobs.filter(
          (_j) => _j?.state === 'completed' && !_j.marked_as_trash
        );
        counter = jobs.length || 0;
      }
      state.sideBarCounters.optimizeAssets = counter;
    },
    decreaseOptimizeAssetsCounter: (state, action) => {
      const job = action?.payload;
      if (job && job?.state !== 'sent') {
        state.sideBarCounters.optimizeAssets -= 1;
      }
    },
    setOnboardingData: (state, action) => {
      const shops = action?.payload;
      if (shops && shops.length) {
        const shop = orderedListById({ list: [...shops], asc: false })[0];
        if (shop?.confetti) state.onboarding.noContentPushed = false;
        else {
          state.onboarding.shopId = shop?.id;
        }
      } else {
        state.onboarding.noContentPushed = true;
      }
    },
    setNoContentPushed: (state, action) => {
      state.onboarding.noContentPushed = action?.payload;
    },
    setHiddenSideBarIcons: (state, action) => {
      state.hiddenSideBarIcons = action?.payload;
    },
  },
});

// ------------------EXPORT REDUCERS-------------
export const {
  toggleSideBar,
  toggleSideBarItem,
  toggleRegenerateAllRejectedModal,
  toggleInviteMemberModal,
  togglePushToCMSConfirmationModal,
  toggleSetupAutomaticIndexingModal,
  toggleReportBugModal,
  toggleReIndexingModal,
  toggleChangePasswordModal,
  toggleRequestPricingModal,
  toggleMobileMenuOpen,
  toggleAccessDeniedModal,
  toggleRequestUpgradeModal,
  setAccessKey,
  togglePushToCMSModal,
  toggleRequestIntegrationModal,
  toggleIntegrationConnectedModal,
  toggleBrandGuidelinesUploadModal,
  toggleKeyImprovementsModal,
  setBulkJobTab,
  setAutopilotJobTab,
  setOptimizeAssetsCounter,
  decreaseOptimizeAssetsCounter,
  setOnboardingData,
  setNoContentPushed,
  toggleActivityLogModal,
  toggleUndoContentUpdateModal,
  setHiddenSideBarIcons,
} = navigationSlice.actions;
export default navigationSlice.reducer;

export const selectNavigation = (state) => state.navigation;
export const selectNavigationLoading = (state) => state.navigation.loading;
export const selectSideBarOpen = (state) => state.navigation.sideBarOpen;

export const selectRegenerateAllRejectedModal = (state) =>
  state.navigation.regenerateAllRejectedModal;
export const selectReportBugModal = (state) => state.navigation.reportBugModal;
export const selectInviteMemberModal = (state) =>
  state.navigation.inviteMemberModal;
export const selectChangePasswordModal = (state) =>
  state.navigation.changePasswordModal;
export const selectRequestPricingModal = (state) =>
  state.navigation.requestPricingModal;

export const selectMobileMenuOpen = (state) => state.navigation.mobileMenuOpen;

export const selectAccessDeniedModal = (state) =>
  state.navigation.accessDeniedModal;

export const selectActivityLogModalOpen = (state) =>
  state.navigation.activityLogModalOpen;

export const selectUndoContentUpdateModalOpen = (state) =>
  state.navigation.undoContentUpdateModalOpen;

export const selectReIndexingModal = (state) =>
  state.navigation.reIndexingModal;

export const selectRequestUpgradeModal = (state) =>
  state.navigation.requestUpgradeModal;

export const selectRequestIntegrationModal = (state) =>
  state.navigation.requestIntegrationModal;

export const selectBrandGuidelinesUploadModal = (state) =>
  state.navigation.brandGuidelinesUploadModal;

export const selectAccessKey = (state) => state.navigation.accessKey;

export const selectPushToCMSModal = (state) => state.navigation.pushToCMSModal;
export const selectPushToCMSConfirmationModal = (state) =>
  state.navigation.pushToCMSConfirmationModal;
export const selectSetupAutomaticIndexingModal = (state) =>
  state.navigation.setupAutomaticIndexingModal;
export const selectIntegrationConnectedModal = (state) =>
  state.navigation.integrationConnectedModal;
export const selectKeyImprovementsModal = (state) =>
  state.navigation.keyImprovementsModal;
export const selectBulkJobTab = (state) => state.navigation.bulkJobTab;
export const selectAutopilotJobTab = (state) =>
  state.navigation.autopilotJobTab;
export const selectSideBarCounters = (state) =>
  state.navigation.sideBarCounters;
export const selectOnboardingData = (state) => state.navigation.onboarding;
export const selectHiddenSideBarIcons = (state) =>
  state.navigation.hiddenSideBarIcons;
