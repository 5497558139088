import React, { useEffect, useRef } from 'react';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

Chart.register(ArcElement, Tooltip, Legend);

const CustomChart = ({ data }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const chartRef = useRef(null);

  useEffect(() => {
    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }
    };
  }, []);

  const chartLabels = Object.values(data).map((item) => item.label);
  const chartValues = Object.values(data).map((item) => item.value);

  const chartData = {
    labels: chartLabels,
    datasets: [
      {
        label: t('adminDashboard.customerMetrics.plans'),
        data: chartValues,
        backgroundColor: [
          theme.palette.info.dark,
          theme.palette.info.main,
          theme.palette.success.main,
          theme.palette.warning.main,
        ],
      },
    ],
  };

  const chartOptions = {
    animation: true,
    plugins: {
      legend: {
        position: 'right',
        align: 'center',
        labels: {
          boxWidth: 15,
          padding: 10,
          color: theme.palette.text.primary,
        },
      },
    },
    layout: {
      padding: 5,
    },
    maintainAspectRatio: false,
  };

  return (
    <Box className="flex items-center justify-between w-full h-[150px]">
      <Doughnut
        ref={(el) => {
          if (chartRef.current && chartRef.current !== el) {
            chartRef.current.destroy();
          }
          chartRef.current = el;
        }}
        data={chartData}
        options={chartOptions}
        style={{ flex: 1 }}
      />
    </Box>
  );
};

export default CustomChart;
