import PropTypes from 'prop-types';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
  Paper,
  Checkbox,
  Typography,
  Tooltip,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import InfiniteScroll from 'react-infinite-scroll-component';
import {
  getAdminTableData,
  selectDashboardAdminData,
} from '@redux/slices/dashboard';
import { useDispatch, useSelector } from 'react-redux';
import { WriterlySpinner } from '@shared/Loader/Spinner';
import { TitleWithTooltip } from '@shared/Text/TitleWithTooltip';

export const AdminTables = ({ data = [] }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { page, hasMore, loading, loadingPagination } = useSelector(
    selectDashboardAdminData
  );

  const sharedHeaders = [
    { key: 'id', label: t('adminDashboard.table.headers.shopId') },
    {
      key: 'public_shop_url',
      label: t('adminDashboard.table.headers.shopUrl'),
      maxWidth: '240px',
    },
  ];

  const tableHeadersGroup1 = [
    ...sharedHeaders,
    {
      key: 'is_cadence_active',
      label: t('adminDashboard.table.headers.cadenceActive'),
      render: (value) => <Checkbox checked={value} disabled />,
    },
    {
      key: 'next_cadence_cycle',
      label: t('adminDashboard.table.headers.nextCadenceCycle'),
    },
    {
      key: 'next_pull_sync',
      label: t('adminDashboard.table.headers.nextPullSync'),
      maxWidth: '240px',
    },
    {
      key: 'cadence_start_date',
      label: t('adminDashboard.table.headers.cadenceStartDate'),
    },
    {
      key: 'full_catalog_optimization_date',
      label: t('adminDashboard.table.headers.fullCatalogOptimization'),
    },
    {
      key: 'cadence_stage',
      label: t('adminDashboard.table.headers.cadenceStage'),
    },
    {
      key: 'display_comment_ui',
      label: t('adminDashboard.table.headers.displayCommentUI'),
      render: (value) => <Checkbox checked={value} disabled />,
    },
    {
      key: 'auto_approval',
      label: t('adminDashboard.table.headers.autoApproval'),
      render: (value) => <Checkbox checked={value} disabled />,
    },
  ];

  const tableHeadersGroup2 = [
    ...sharedHeaders,
    { key: 'plan_name', label: t('adminDashboard.table.headers.planName') },
    { key: 'plan_period', label: t('adminDashboard.table.headers.planPeriod') },
    {
      key: 'plan_pdps',
      label: t('adminDashboard.table.headers.planPDPs'),
      render: (value) => {
        if (value) {
          return value.toLocaleString();
        }
        return 0;
      },
    },
    {
      key: 'pdps_available',
      label: t('adminDashboard.table.headers.pdpsAvailable'),
      render: (value) => {
        if (value) {
          return value.toLocaleString();
        }
        return 0;
      },
    },
    {
      key: 'plan_price',
      label: t('adminDashboard.table.headers.planPrice'),
      render: (value) => `$${Number(value).toFixed(2)}`,
    },
  ];

  const tableHeadersGroup3 = [
    ...sharedHeaders,
    {
      key: 'pdps_used',
      label: t('adminDashboard.table.headers.pdpsUsed'),
      render: (value) => {
        if (value) {
          return value.toLocaleString();
        }
        return 0;
      },
    },
    {
      key: 'openai_cogs',
      label: (
        <TitleWithTooltip
          title={t('adminDashboard.table.headers.openaiCosts')}
          tooltipText={t('adminDashboard.table.tooltips.openaiCosts', {
            cost: (data[0]?.cogs_breakdown.openai.cost_per_pdp || 0).toFixed(3),
          })}
          containerClassName="justify-center"
        />
      ),
      render: (value) => (
        <Tooltip
          title={
            <div className="text-left block text-sm py-2 px-3 max-w-xs">
              {value}
            </div>
          }
        >
          <span>${value.toFixed(2)}</span>
        </Tooltip>
      ),
    },
    {
      key: 'semrush_cogs',
      label: (
        <TitleWithTooltip
          title={t('adminDashboard.table.headers.semrushCosts')}
          tooltipText={t('adminDashboard.table.tooltips.semrushCosts', {
            cost: (data[0]?.cogs_breakdown.semrush.cost_per_pdp || 0).toFixed(
              3
            ),
          })}
          containerClassName="justify-center"
        />
      ),
      render: (value) => (
        <Tooltip
          title={
            <div className="text-left block text-sm py-2 px-3 max-w-xs">
              {value}
            </div>
          }
        >
          <span>${value.toFixed(2)}</span>
        </Tooltip>
      ),
    },
    {
      key: 'aws_cogs',
      label: (
        <TitleWithTooltip
          title={t('adminDashboard.table.headers.awsCosts')}
          tooltipText={t('adminDashboard.table.tooltips.awsCosts', {
            cost: (data[0]?.cogs_breakdown.aws.cost_per_pdp || 0).toFixed(3),
          })}
          containerClassName="justify-center"
        />
      ),
      render: (value) => (
        <Tooltip
          title={
            <div className="text-left block text-sm py-2 px-3 max-w-xs">
              {value}
            </div>
          }
        >
          <span>${value.toFixed(2)}</span>
        </Tooltip>
      ),
    },
    {
      key: 'total_cogs',
      label: (
        <TitleWithTooltip
          title={t('adminDashboard.table.headers.totalCosts')}
          tooltipText={t('adminDashboard.table.tooltips.totalCosts', {
            cost: (data[0]?.cogs_per_pdp || 0).toFixed(3),
          })}
          containerClassName="justify-center"
        />
      ),
      render: (value) => (
        <Tooltip
          title={
            <div className="text-left block text-sm py-2 px-3 max-w-xs">
              {value}
            </div>
          }
        >
          <span>${value.toFixed(2)}</span>
        </Tooltip>
      ),
    },
  ];

  const fetchMoreItems = () => {
    dispatch(
      getAdminTableData({
        page: page + 1,
      })
    );
  };

  const renderTable = (headers, tableData, title) => (
    <Box className="mb-10">
      <Typography variant="h4" className="font-bold mb-4 self-start">
        {title}
      </Typography>
      <InfiniteScroll
        dataLength={tableData.length}
        next={fetchMoreItems}
        hasMore={hasMore}
        scrollableTarget={`admin-table-${title}`}
        className="pt-2 !overflow-hidden rounded-3xl"
        style={{ backgroundColor: theme.palette.table.background.default }}
      >
        <TableContainer
          component={Paper}
          sx={{
            backgroundColor: theme.palette.table.background.default,
            maxHeight: '600px',
            overflowY: 'auto',
            marginBottom: '16px',
          }}
          id={`admin-table-${title}`}
          className="p-2 pt-0 pr-0.5 flex flex-col !writerly-custom-scroll rounded-3xl"
        >
          <Table stickyHeader={true} className="rounded-2xl">
            <TableHead>
              <TableRow>
                {headers.map((header, index) => (
                  <TableCell
                    key={header.key + index}
                    sx={{
                      color: theme.palette.text.primary,
                      position: 'sticky',
                      top: 0,
                      backgroundColor: theme.palette.table.background.header,
                      zIndex: 1,
                      borderTopLeftRadius: index === 0 ? '16px' : '0',
                      borderTopRightRadius:
                        index === headers.length - 1 ? '16px' : '0',
                      maxWidth: header.maxWidth || 'auto',
                      whiteSpace: header.maxWidth ? 'nowrap' : 'normal',
                      overflow: header.maxWidth ? 'hidden' : 'visible',
                      textOverflow: header.maxWidth ? 'ellipsis' : 'clip',
                      textAlign: 'center',
                    }}
                  >
                    {header.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData?.length > 0 ? (
                tableData.map((row) => (
                  <TableRow
                    key={row.id}
                    className="border-0 group"
                    sx={{
                      backgroundColor: 'transparent',
                      '&:hover': {
                        backgroundColor:
                          theme.palette.metrics.card.background.secondary,
                      },
                    }}
                  >
                    {headers.map((header) => (
                      <TableCell
                        className="group-last:!border-b-0"
                        key={header.key}
                        sx={{
                          color: theme.palette.text.primary,
                          borderColor: theme.palette.table.border,
                          ':first-of-type': {
                            borderRight: '1px solid',
                            borderColor: theme.palette.table.border,
                          },
                          maxWidth: header.maxWidth || 'auto',
                          whiteSpace: header.maxWidth ? 'nowrap' : 'normal',
                          overflow: header.maxWidth ? 'hidden' : 'visible',
                          textOverflow: header.maxWidth ? 'ellipsis' : 'clip',
                          textAlign: 'center',
                        }}
                      >
                        {header.render
                          ? header.render(row[header.key])
                          : row[header.key]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={headers.length}
                    align="center"
                    className="border-0"
                  >
                    {t('dashboard.metrics.table.empty')}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {loadingPagination && tableData.length > 0 && (
            <div className="mx-auto !mt-4">
              <WriterlySpinner variant="medium" />
            </div>
          )}
        </TableContainer>
      </InfiniteScroll>
    </Box>
  );

  const renderComponent = () => (
    <Box className="w-full">
      {renderTable(
        tableHeadersGroup1,
        data,
        t('adminDashboard.table.titles.cadence')
      )}
      {renderTable(
        tableHeadersGroup3,
        data,
        t('adminDashboard.table.titles.costOfGoodsSold')
      )}
      {renderTable(
        tableHeadersGroup2,
        data,
        t('adminDashboard.table.titles.plans')
      )}
    </Box>
  );

  return (
    <Box className="relative xl:px-[5%] w-full flex items-center flex-col justify-center">
      {loading ? (
        <Box className="mt-10">
          <WriterlySpinner />
        </Box>
      ) : (
        renderComponent()
      )}
    </Box>
  );
};

AdminTables.propTypes = {
  data: PropTypes.array,
};
