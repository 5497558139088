import CustomChart from '@shared/AdminDashboard/CustomChart.jsx';
import { useTranslation } from 'react-i18next';
import { capitalizeWord } from '@utils/validations.js';
import MetricsGrid from '@shared/AdminDashboard/MetricsGrid.jsx';

const CustomerMetrics = ({ globalMetrics }) => {
  const { t } = useTranslation();

  const charData = {
    growth: {
      label: t('adminDashboard.customerMetrics.dataLabels.growth'),
      value: globalMetrics?.plan_type_counts?.growth,
    },
    essentials: {
      label: t('adminDashboard.customerMetrics.dataLabels.essentials'),
      value: globalMetrics?.plan_type_counts?.essentials,
    },
    custom: {
      label: t('adminDashboard.customerMetrics.dataLabels.custom'),
      value: globalMetrics?.plan_type_counts?.custom,
    },
    noPlan: {
      label: t('adminDashboard.customerMetrics.dataLabels.noPlan'),
      value: globalMetrics?.plan_type_counts?.no_plan,
    },
  };

  const formattedData = [
    {
      title: t('adminDashboard.customerMetrics.activeUsers.title'),
      value:
        globalMetrics?.users_active ??
        t('adminDashboard.customerMetrics.noValuePlaceHolder'),
      tooltip: t('adminDashboard.customerMetrics.activeUsers.tooltip'),
    },
    {
      title: t('adminDashboard.customerMetrics.averageAge'),
      value: globalMetrics?.average_organization_active_days
        ? `${Math.round(
            globalMetrics.average_organization_active_days
          )} ${capitalizeWord(t('dayPlural'))}`
        : t('adminDashboard.customerMetrics.noValuePlaceHolder'),
    },
    {
      title: t('adminDashboard.customerMetrics.plans'),
      value: <CustomChart data={charData} />,
    },
  ];

  return (
    <MetricsGrid
      title="adminDashboard.customerMetrics.title"
      data={formattedData}
    />
  );
};

export default CustomerMetrics;
