import { alpha } from '@mui/material/styles';

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const PRIMARY = {
  lighter: '#fff',
  light: '#fff',
  main: '#CBA376',
  dark: '#942430',
  darker: '#000000',
};
const SECONDARY = {
  lighter: '#F2F3F5',
  light: '#E2E8F0',
  main: '#C5CBD3',
  dark: '#B0B6BE',
  darker: '#7A818C',
};
const INFO = {
  lighter: '#f6aeb9',
  light: '#ee818e',
  main: '#CBA376',
  dark: '#f83f5a',
  darker: '#9f2e3e',
  darkButton: '#000',
};
const SUCCESS = {
  lighter: '#a7e8d8',
  light: '#8fd9c8',
  main: '#56DABA',
  dark: '#3c9682',
  darker: '#22594a',
  vivid: '#22c55e',
};
const WARNING = {
  lighter: '#FFFBCC',
  light: '#FFF066',
  main: '#FFE100',
  dark: '#B79D00',
  darker: '#7A6500',
};
const ERROR = {
  lighter: '#FFE6D6',
  light: '#FFA083',
  main: '#FF4032',
  dark: '#B71928',
  darker: '#7A0927',
  fields: '#F10000',
};
const ICONS = {
  darker: '#000',
};

const GREY = {
  0: '#FFFFFF',
  100: '#F6F6F6',
  200: '#F2F3F5',
  300: '#E2E8F0',
  400: '#C5CBD3',
  500: '#B0B6BE',
  600: '#7A818C',
  700: '#5D5D5D',
  800: '#161C24',
  900: '#1C1C1C',
  '0_0': alpha('#FFFFFF', 0.5),
  300_5: alpha('#E2E8F0', 0.5),
  400_5: alpha('#C5CBD3', 0.5),
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8),
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, '#70AEF4'),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
  cardPrice: 'linear-gradient(90deg, #CCA377 0%, #942430 86%)',
  cardChooseButton: 'linear-gradient(90deg, #CCA377 -78.84%, #CCA377 100%)',
  connectButton: 'linear-gradient(to right, #942430, #CCA377)',
  authEkomTitle: `linear-gradient(92deg, #CBA376 30%, #942430 100%)`,
  stepAuthItem: 'linear-gradient(145deg, #CBA376 0%, #942430 100%)',
  icons: {
    primary: {
      from: '#942430',
      to: '#942430',
    },
    success: {
      from: '#CCA377',
      to: '#CCA377',
    },
  },
};

const COMMON = {
  common: {
    black: '#000',
    white: '#fff',
    primary: '#CCA377',
    darker: '#000',
  },
  primary: { ...PRIMARY, contrastText: '#fff' },
  secondary: { ...SECONDARY, contrastText: '#fff' },
  info: { ...INFO, contrastText: '#fff' },
  success: { ...SUCCESS, contrastText: GREY[800] },
  warning: { ...WARNING, contrastText: GREY[800] },
  error: { ...ERROR, contrastText: '#fff' },
  grey: GREY,
  gradients: GRADIENTS,
  menuIcon: '#CCA377',
  optimizeJob: '#B9B9B9',
  productListing: '#CCA377',
  optimizeTableLegend: {
    border: '#BFBFBF',
  },
  divider: GREY[500_24],
  action: {
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
  dropdown: {
    hoverBg: '#f6f6f6',
  },
  cmsIcons: {
    woocommerce: {
      cardVersion: {
        stroke: 'none',
        svgFill: ICONS.darker,
      },
      activityLogVersion: {
        stroke: ICONS.darker,
        svgFill: GREY[0],
      },
      integrationButtonVersion: {
        stroke: 'none',
        svgFill: ICONS.darker,
      },
      detailTableButtonVersion: {
        stroke: 'none',
        svgFill: ICONS.darker,
      },
    },
    shopify: {
      integrationButtonVersion: {
        stroke: 'none',
        svgFill: ICONS.darker,
      },
      detailTableButtonVersion: {
        stroke: 'none',
        svgFill: ICONS.darker,
      },
    },
    magento: {
      detailTableButtonVersion: {
        stroke: 'none',
        svgFill: ICONS.darker,
      },
    },
    akeneo: {
      integrationButtonVersion: {
        stroke: 'none',
        svgFill: ICONS.darker,
      },
      detailTableButtonVersion: {
        stroke: 'none',
        svgFill: ICONS.darker,
      },
    },
    bigcommerce: {
      cardVersion: {
        svgFill: ICONS.darker,
      },
      integrationButtonVersion: {
        stroke: 'none',
        svgFill: ICONS.darker,
      },
      detailTableButtonVersion: {
        stroke: 'none',
        svgFill: ICONS.darker,
      },
    },
    webflow: {
      cardVersion: {
        svgFill: ICONS.darker,
      },
      integrationButtonVersion: {
        stroke: 'none',
        svgFill: ICONS.darker,
      },
      detailTableButtonVersion: {
        stroke: 'none',
        svgFill: ICONS.darker,
      },
    },
    hibbett: {
      cardVersion: {
        svgFill: ICONS.darker,
      },
      integrationButtonVersion: {
        stroke: 'none',
        svgFill: ICONS.darker,
      },
      detailTableButtonVersion: {
        stroke: 'none',
        svgFill: ICONS.darker,
      },
    },
  },
  checkbox: {
    checkedAuth: '#046AC3',
  },
  actionItems: {
    background: '#0B4B62',
    step: '#115D78',
  },
};

export { GREY, COMMON, PRIMARY, INFO, GRADIENTS, alpha };
