import { Box, Grid, Card, CardContent, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { TitleWithTooltip } from '@shared/Text/TitleWithTooltip.jsx';

const GlobalMetrics = ({ globalMetrics, extraData }) => {
  const theme = useTheme();

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={3} justifyContent="center">
        {Object.entries(globalMetrics).map(([key, value]) => {
          if (!extraData[key]) return null; // Skip unknown keys

          return (
            <Grid item lg={6} md={6} sm={12} xl={6} xs={12} key={key}>
              <Card
                sx={{
                  backgroundColor: theme.palette.sidebar.background,
                  borderRadius: 1,
                  height: '100%',
                  width: '100%',
                  textAlign: 'center',
                  padding: 2,
                }}
              >
                <CardContent>
                  <Typography
                    variant="h4"
                    sx={{
                      color: theme.palette.text.primary,
                      fontWeight: 'bold',
                    }}
                  >
                    {value?.toLocaleString() ?? '0'}
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 'bold',
                      color: theme.palette.text.primary,
                    }}
                  >
                    {extraData[key].label}
                  </Typography>
                  <Box className="flex justify-center">
                    <TitleWithTooltip
                      titleClassName="text-sm"
                      title={extraData[key].subLabel}
                      tooltipText={extraData[key].tooltip}
                    />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default GlobalMetrics;
