// @import Dependencies
import { lazy } from 'react';

// @import Guards
import GuestGuard from '@guards/GuestGuard';
import AddIntegrationGuard from '@guards/AddIntegrationGuard';
import AuthGuard from '@guards/AuthGuard';
import AdminGuard from '@guards/AdminGuard';
import PlanGuard from '@guards/PlanGuard';

// @import Custom Components
import Loadable from '@shared/Lazy/LazyComponent';
import EmailVerificationGuard from '@guards/EmailVerificationGuard';
import AdminDashboard from '@pages/adminDashboard/adminDashboard.jsx';

// @import Lazy Components
const App = Loadable(lazy(() => import('src/App')));
const ReusableComponents = Loadable(
  lazy(() => import('@pages/reusableComponents/reusableComponent'))
);
const CommonError = Loadable(lazy(() => import('@pages/error/error')));
const Platform = Loadable(lazy(() => import('@platform/Platform')));
const AuthSignUpInvitation = Loadable(
  lazy(() => import('@pages/auth/signUp/signUpInvitation'))
);
const AuthSignUp = Loadable(lazy(() => import('@pages/auth/signUp/signUp')));

const AuthLogin = Loadable(lazy(() => import('@pages/auth/login/login')));
const AuthLoginEmbedded = Loadable(
  lazy(() => import('@pages/auth/login/loginEmbedded'))
);
const AuthPasswordRecovery = Loadable(
  lazy(() => import('@pages/auth/passwordRecovery'))
);
const AuthSetNewPassword = Loadable(
  lazy(() => import('@pages/auth/setNewPassword'))
);
const Success = Loadable(lazy(() => import('@pages/success/success')));
const AuthverifyEmail = Loadable(lazy(() => import('@pages/auth/verifyEmail')));
const OptimizeAssetDetails = Loadable(
  lazy(() => import('@pages/optimizeAssets/assetDetails'))
);
const EkomTeamMemberSignup = Loadable(
  lazy(() => import('@pages/auth/signUp/teamMemberSignUp'))
);
const Dashboard = Loadable(lazy(() => import('@pages/dashboard/dashboard')));

const OptimizeLog = Loadable(
  lazy(() => import('@pages/optimizeLog/optimizeLog'))
);
const SampleApprovals = Loadable(
  lazy(() => import('@pages/optimizeLog/sampleApprovals'))
);

const OptimizeSettingsForm = Loadable(
  lazy(() => import('@pages/optimizeSettings/settingsForm'))
);

const BillingRedirection = Loadable(
  lazy(() => import('@pages/billing/billingRedirection'))
);
const SubscriptionPortal = Loadable(
  lazy(() => import('@pages/billing/subscriptionPortal'))
);
const Account = Loadable(lazy(() => import('@pages/account/account')));
const Settings = Loadable(lazy(() => import('@pages/account/settings')));
const Integrations = Loadable(
  lazy(() => import('@pages/account/integrations'))
);
const TeamMembers = Loadable(lazy(() => import('@pages/account/teamMembers')));
const Subscription = Loadable(
  lazy(() => import('@pages/account/subscription'))
);

const OptimizeAssetList = Loadable(
  lazy(() => import('@pages/optimizeAssets/assetsList'))
);
const OptimizePage = Loadable(
  lazy(() => import('@pages/optimizeAssets/optimizePage'))
);
const UpgradePlan = Loadable(lazy(() => import('@pages/billing/upgradePlan')));
const Onboarding = Loadable(lazy(() => import('@pages/onboarding/onboarding')));

const renderReusableComponents =
  import.meta.env.VITE_ENV === 'develop' ||
  import.meta.env.VITE_ENV === 'staging';

export const router = [
  {
    path: '/',
    children: [
      {
        index: true,
        element: (
          <EmailVerificationGuard>
            <GuestGuard>
              <AuthLogin />
            </GuestGuard>
          </EmailVerificationGuard>
        ),
      },
      {
        path: 'login',
        element: (
          <GuestGuard>
            <AuthLogin />
          </GuestGuard>
        ),
      },
      {
        path: 'signup',
        element: (
          <GuestGuard>
            <AuthSignUp />
          </GuestGuard>
        ),
      },
      {
        path: 'sign-up/invitation',
        element: <AuthSignUpInvitation />,
      },
      {
        path: 'join-your-team',
        element: (
          <GuestGuard>
            <EkomTeamMemberSignup />
          </GuestGuard>
        ),
      },
      {
        path: 'password-recovery',
        element: (
          <GuestGuard>
            <AuthPasswordRecovery />
          </GuestGuard>
        ),
      },
      {
        path: 'set-new-password/:token',
        element: (
          <GuestGuard>
            <AuthSetNewPassword />
          </GuestGuard>
        ),
      },
      {
        path: 'verify-email',
        element: (
          <GuestGuard>
            <AuthverifyEmail />
          </GuestGuard>
        ),
        children: [
          {
            path: ':token',
            element: <AuthverifyEmail />,
          },
        ],
      },
      {
        path: 'subscription-portal',
        element: (
          <AuthGuard>
            <SubscriptionPortal />
          </AuthGuard>
        ),
      },
      {
        path: 'login-embedded',
        element: <AuthLoginEmbedded />,
      },
    ],
    errorElement: <CommonError />,
  },
  {
    path: 'app',
    element: (
      <AuthGuard>
        <PlanGuard>
          <AddIntegrationGuard>
            <EmailVerificationGuard>
              <Platform />
            </EmailVerificationGuard>
          </AddIntegrationGuard>
        </PlanGuard>
      </AuthGuard>
    ),
    children: [
      {
        path: 'autopilot',
        children: [
          { index: true, element: <OptimizePage /> },
          {
            path: 'autopilot-jobs',
            children: [
              { path: ':id', element: <OptimizeAssetDetails /> },
              {
                path: ':type',
                children: [{ path: ':state', element: <OptimizeAssetList /> }],
              },
            ],
          },
        ],
      },
      {
        path: 'dashboard',
        children: [{ index: true, element: <Dashboard /> }],
      },
      {
        path: 'admin-dashboard',
        children: [{ index: true, element: <AdminDashboard /> }],
      },
      {
        path: 'optimization-log',
        children: [
          { index: true, element: <OptimizeLog /> },
          { path: 'sample-approvals', element: <SampleApprovals /> },
        ],
      },
      {
        path: 'settings',
        children: [{ index: true, element: <OptimizeSettingsForm /> }],
      },
      {
        path: 'onboarding',
        children: [{ index: true, element: <Onboarding /> }],
      },
      { path: 'success', element: <Success /> },
      {
        path: 'account',
        element: <Account />,
        children: [
          {
            path: 'settings',
            element: <Settings />,
          },
          {
            path: 'integrations',
            element: <Integrations />,
          },
          {
            path: 'subscription',
            element: (
              <AdminGuard>
                <Subscription />
              </AdminGuard>
            ),
          },
          {
            path: 'team-members',
            element: (
              <AdminGuard>
                <TeamMembers />
              </AdminGuard>
            ),
          },
        ],
      },
      {
        path: 'signup-plan',
        element: <UpgradePlan />,
      },
      {
        path: 'billing-redirection',
        element: <BillingRedirection />,
      },
      renderReusableComponents && {
        path: 'test-reusable-components',
        element: <ReusableComponents />,
      },
      {
        path: 'test-error-component-500',
        element: <CommonError code={500} />,
      },
      {
        path: 'test-error-component-1020',
        element: <CommonError code={1020} />,
      },
      {
        path: 'test-error-component-404',
        element: <CommonError code={404} />,
      },
      { index: true, element: <App /> },
    ],
  },
];
