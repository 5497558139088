export default function Chip(theme) {
  return {
    MuiChip: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          display: 'flex',
          justifyContent: 'space-between',
          paddingLeft: 10,
          paddingRight: 10,

          // Filled
          ...(ownerState.variant === 'filled' && {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.background.default,
          }),

          // Filled 2
          ...(ownerState.variant === 'filled2' && {
            backgroundColor: theme.palette.text.primary,
            color: theme.palette.background.default,
          }),

          // Outlined
          ...(ownerState.variant === 'outlined' && {
            borderColor: theme.palette.secondary.darker,
            color: theme.palette.secondary.darker,
            backgroundColor: 'transparent',

            '&:hover': {
              borderColor: theme.palette.secondary.darker,
              color: theme.palette.secondary.darker,
              backgroundColor: 'transparent',
            },
            '&.Mui-disabled': {
              borderColor: theme.palette.secondary.darker,
              color: theme.palette.secondary.darker,
              backgroundColor: 'transparent',
              opacity: 0.4,
            },
          }),
        }),
      },
    },
  };
}
