import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavbarHeader } from '@shared/Navbar/NavbarHeader';
import { WriterlyNavBar } from '@shared/Navbar/Navbar';
import { useSelector, useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { canAccessDashboard } from '@shared/Dashboard/Utils';
import { selectUserData } from '@redux/slices/user';
import { authPaths } from '@routes/paths';
import { useNavigate } from 'react-router-dom';
import {
  getAdminHistoricalData,
  getAdminOverviewData,
  getAdminTableData,
  selectDashboardAdminData,
} from '@redux/slices/dashboard';
import { AdminTables } from '@shared/Dashboard/AdminTables';
import IntervalMetrics from '@shared/AdminDashboard/IntervalMetrics';
import GlobalMetrics from '@shared/AdminDashboard/GlobalMetrics';
import { WriterlySpinner } from '@shared/Loader/Spinner';
import PDPCostMetrics from '@shared/AdminDashboard/PDPCostMetrics';
import CogsMeasureGraph from '@shared/AdminDashboard/CogsMeasureGraph';
import CustomerMetrics from '@shared/AdminDashboard/CustomerMetrics';

const AdminDashboard = () => {
  const { t } = useTranslation();
  const [selectedInterval, setSelectedInterval] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector(selectUserData);
  const { table, overview, loadingOverview, loadingHistorical, historical } =
    useSelector(selectDashboardAdminData);
  const { haveAccessAsAdmin } = canAccessDashboard({ user });

  useEffect(() => {
    if (!haveAccessAsAdmin) {
      navigate(authPaths.home);
    }
  }, []);

  useEffect(() => {
    dispatch(getAdminTableData({ page: 1 }));
    dispatch(getAdminOverviewData());
    dispatch(getAdminHistoricalData());
  }, []);

  const extraData = {
    shop_in_sample_phase: {
      label: t('adminDashboard.shopInSamplePhase.label'),
      subLabel: t('adminDashboard.shopInSamplePhase.subLabel'),
      tooltip: t('adminDashboard.shopInSamplePhase.tooltip'),
    },
    auto_approval_shops: {
      label: t('adminDashboard.autoApprovalShops.label'),
      subLabel: t('adminDashboard.autoApprovalShops.subLabel'),
      tooltip: t('adminDashboard.autoApprovalShops.tooltip'),
    },
    total_active_products: {
      label: t('adminDashboard.totalActiveProducts.label'),
      subLabel: t('adminDashboard.totalActiveProducts.subLabel'),
      tooltip: t('adminDashboard.totalActiveProducts.tooltip'),
    },
    total_products_including_inactive: {
      label: t('adminDashboard.totalProductsIncludingInactive.label'),
      subLabel: t('adminDashboard.totalProductsIncludingInactive.subLabel'),
      tooltip: t('adminDashboard.totalProductsIncludingInactive.tooltip'),
    },
  };

  return (
    <section className="h-screen flex flex-col overflow-y-scroll writerly-custom-scroll">
      <NavbarHeader>
        <WriterlyNavBar
          title={t('adminDashboard.title')}
          navExtraStyle="!px-6 !pt-10 !pb-10"
        />
      </NavbarHeader>
      <Box className="flex flex-col items-center">
        {loadingOverview ? (
          <Box className="mt-10">
            <WriterlySpinner />
          </Box>
        ) : (
          overview && (
            <Box className="xl:px-[5%] flex-none w-full">
              <IntervalMetrics
                intervalMetrics={overview?.interval_metrics}
                selectedInterval={selectedInterval}
                setSelectedInterval={setSelectedInterval}
              />
              <GlobalMetrics
                globalMetrics={overview?.global_metrics}
                extraData={extraData}
              />
              <CustomerMetrics globalMetrics={overview?.global_metrics} />
              <PDPCostMetrics pdpCosts={overview?.pdpCosts} />
            </Box>
          )
        )}
        {loadingHistorical ? (
          <Box className="mt-10">
            <WriterlySpinner />
          </Box>
        ) : (
          historical && (
            <Box className="flex items-center mt-12 flex-col w-full">
              <CogsMeasureGraph graphData={historical} />
            </Box>
          )
        )}
        <Box className="flex items-center mt-12 flex-col w-full">
          <AdminTables data={table} />
        </Box>
      </Box>
    </section>
  );
};

export default AdminDashboard;
