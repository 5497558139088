import PropTypes from 'prop-types';
import CustomTabs from '@shared/Tabs/CustomTabs';
import TabPanel from '@shared/Tabs/TabPanel';

export const WriterlyTabs = ({
  children,
  tabsData,
  handleClickTab,
  extraButtons,
}) => {
  return (
    <CustomTabs handleClickTab={handleClickTab} extraButtons={extraButtons}>
      {tabsData.map((tab, index) => (
        <TabPanel
          key={index}
          label={tab.label}
          index={index}
          value={index}
          to={tab.href}
          id={tab.id}
        >
          {children}
        </TabPanel>
      ))}
    </CustomTabs>
  );
};

WriterlyTabs.propTypes = {
  children: PropTypes.node,
  extraButtons: PropTypes.any,
  handleClickTab: PropTypes.func,
  tabsData: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
      id: PropTypes.string,
    }).isRequired
  ).isRequired,
};
