import PropTypes from 'prop-types';
import { IconButton, Tooltip, useTheme } from '@mui/material';
import { WriterlyIcon } from '@shared/Icon/Icon';

export const TitleWithTooltip = ({
  title,
  tooltipText,
  titleClassName = '',
  containerClassName = '',
  allowWrap = false,
  customIcon = {},
}) => {
  const theme = useTheme();
  return (
    <div
      className={`flex flex-row items-center gap-2 ${
        allowWrap ? '' : 'whitespace-nowrap'
      } ${containerClassName}`}
    >
      <span className={titleClassName}>
        {title}
        {tooltipText && (
          <Tooltip
            className={'pl-2'}
            title={
              <div className="text-left block text-sm py-2 px-3 max-w-xs">
                {typeof tooltipText === 'string' ? (
                  <span dangerouslySetInnerHTML={{ __html: tooltipText }} />
                ) : (
                  tooltipText
                )}
              </div>
            }
            PopperProps={{
              sx: {
                '& .MuiTooltip-tooltip': {
                  backgroundColor: `${theme.palette.background.paper} !important`,
                  color: theme.palette.text.primary,
                  border: `1px solid ${theme.palette.text.primary}`,
                  borderRadius: '8px',
                  maxWidth: 'none',
                  whiteSpace: 'normal',
                },
              },
            }}
          >
            <IconButton className="ml-2 !p-0 h-4">
              <WriterlyIcon
                iconName={'ExclamationMark'}
                className="border border-solid rounded-full p-[2px]"
                width="16px"
                height="16px"
                color={theme.palette.text.primary}
                {...customIcon}
              />
            </IconButton>
          </Tooltip>
        )}
      </span>
    </div>
  );
};

TitleWithTooltip.propTypes = {
  title: PropTypes.string.isRequired,
  tooltipText: PropTypes.node,
  titleClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  customIcon: PropTypes.any,
  allowWrap: PropTypes.bool,
};
