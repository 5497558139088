import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import {
  getOptimizationHistoryLogAPI,
  getOptimizationLogProductDetailsAPI,
  getOtherRoundsOptionsAPI,
  getOptimizationLogStatisticsCardsAPI,
  getOptimizationLogProductMetricsAPI,
  restoreSuperRowAPI,
  getOptimizationLatestReviewingJobAPI,
  updatePdpStatusAPI,
  newSampleJobAPI,
  rerunSamplesAPI,
  getRerunSampleAPI,
  getSampleApprovalsAPI,
} from '@api/optimizationLog';
import { getAutopilotBatchRowsAPI } from '@api/autopilot';
import {
  formatDateByMonthAndDay,
  formatDateYearMonthDay,
} from '@utils/date.js';
import { nomenclatureSnack } from '@utils/nomenclature';
import { handleRowLimitException, pollRequest } from '@utils/slices';

const initialState = {
  loadingOptimizationLogStatisticsCards: false,
  optimizeLogsCards: { data: null },
  loadingOptimizationHistoryLog: false,
  loadingOptimizationHistoryLogPagination: false,
  loadingLatestReviewingJob: false,
  loadingUpdatePdpStatus: false,
  loadingNewSampleJob: false,
  optimizationHistoryLog: [],
  loadingProductDetails: false,
  productsDetails: {},
  loadingOtherRoundsOptions: false,
  otherRoundsOptions: {},
  selectedProduct: null,
  selectedProductToCompare: null,
  loadingRestoreSuperRow: false,
  optimizationLogPagination: {
    currentPage: 1,
    hasMoreResults: false,
  },
  otherRoundsPagination: {
    currentPage: 1,
    hasMore: false,
  },
  loadingProductMetrics: false,
  loadingSamplesRerun: false,
  loadingSampleApprovals: false,
  productMetrics: {},
  needsApproval: {
    autoApproval: false,
    creatingLatestJob: false,
    hasReviewingJobEnded: false,
    pagination: {
      pending: {
        loading: false,
        currentPage: 1,
        hasMore: false,
        total: 0,
      },
      approved: {
        loading: false,
        currentPage: 1,
        hasMore: false,
        total: 0,
      },
      rejected: {
        loading: false,
        currentPage: 1,
        hasMore: false,
        total: 0,
      },
    },
    job: null,
    allItems: [],
    itemsWithoutStatus: [],
    approvedItems: [],
    rejectedItems: [],
  },
  sampleApprovals: [],
  sampleApprovalsPagination: {
    currentPage: null,
    total: 0,
  },
  dontAskAgainPDP: false,
};

export const getOptimizationLogStatisticsCards = createAsyncThunk(
  'optimizationLog/getOptimizationLogStatisticsCards',
  async (id, thunkAPI) => {
    const { data, isSuccessful, statusKey } =
      await getOptimizationLogStatisticsCardsAPI(id);
    if (isSuccessful) return { ...data, id };
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const fetchOptimizationHistoryLog = createAsyncThunk(
  'optimizationLog/fetchOptimizationHistoryLog',
  async ({ shopId, filterParams }, { dispatch, rejectWithValue }) => {
    try {
      const response = await getOptimizationHistoryLogAPI({
        id: shopId,
        params: {
          ...filterParams,
          from_date: formatDateYearMonthDay(filterParams.from_date),
          to_date: formatDateYearMonthDay(filterParams.to_date),
          [filterParams.searchBy]: filterParams.searchTerm,
        },
      });
      const data = response.data;
      if (data.length > 0 && filterParams.page === 1) {
        const firstCategory = data[0]?.categories[0];
        const firstProduct = firstCategory?.products[0];
        if (firstProduct) {
          dispatch(
            fetchProductDetails({
              shopId,
              itemInfo: firstProduct,
              filterParams,
            })
          );
          dispatch(
            fetchProductMetrics({
              shopId,
              rowId: firstProduct.autopilot_super_row_id,
            })
          );
        }
      }
      return {
        data,
        current_page: filterParams.page || 1,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchProductDetails = createAsyncThunk(
  'optimizationLog/fetchProductDetails',
  async ({ shopId, itemInfo, filterParams }, { rejectWithValue }) => {
    try {
      const response = await getOptimizationLogProductDetailsAPI({
        id: shopId,
        params: {
          autopilot_super_row_id: itemInfo.autopilot_super_row_id,
          ...(filterParams.isFromCompared
            ? { compare_against: filterParams.compare_against }
            : {}),
        },
      });

      return {
        ...response.data,
        isFromCompared: filterParams.isFromCompared,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchProductMetrics = createAsyncThunk(
  'optimizationLog/fetchProductMetrics',
  async ({ shopId, rowId }, { rejectWithValue }) => {
    try {
      const response = await getOptimizationLogProductMetricsAPI({
        shopId,
        rowId,
      });
      if (!response.isSuccessful) {
        return rejectWithValue(response.data);
      }

      return { id: rowId, ...response.data };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchOtherRoundsOptions = createAsyncThunk(
  'optimizationLog/fetchOtherRoundsOptions',
  async ({ shopId, rowId, filterParams }, { dispatch, rejectWithValue }) => {
    try {
      const response = await getOtherRoundsOptionsAPI({
        rowId,
        params: {
          compare_against: filterParams.compare_against,
          page: filterParams.page,
          page_size: 10,
        },
      });
      const data = response.data;
      if (data.length > 0 && filterParams.page === 1) {
        const firstRound = data[0];
        dispatch(
          fetchProductDetails({
            shopId,
            itemInfo: { autopilot_super_row_id: firstRound.row_id },
            filterParams: {
              ...filterParams,
              isFromCompared: true,
            },
          })
        );
      }
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const restoreSuperRow = createAsyncThunk(
  'optimizationLog/restoreSuperRow',
  async ({ body }, { rejectWithValue }) => {
    try {
      const response = await restoreSuperRowAPI({ body });
      if (!response.isSuccessful) {
        return rejectWithValue(response);
      }

      return { ...response.data, statusKey: response.statusKey };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getLatestReviewingJob = createAsyncThunk(
  'optimizationLog/getLatestReviewingJob',
  async ({ shopId }, { rejectWithValue, dispatch }) => {
    try {
      const response = await getOptimizationLatestReviewingJobAPI({
        shopId,
      });
      if (!response.isSuccessful) {
        return rejectWithValue(response.statusKey);
      }
      ['Pending', 'Approved', 'Rejected'].forEach((status) => {
        dispatch(
          getLatestReviewingJobRows({
            id: response.data.job.id,
            feedbackStatus: status,
            pageSize: 20,
          })
        );
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getLatestReviewingJobRows = createAsyncThunk(
  'optimizationLog/getLatestReviewingJobRows',
  async (body, thunkAPI) => {
    const {
      data: res,
      isSuccessful,
      statusKey,
    } = await getAutopilotBatchRowsAPI(body);
    if (isSuccessful) {
      return {
        rowsResponse: res,
        page: body?.page ?? 1,
        feedbackStatus: body?.feedbackStatus,
      };
    }
    return thunkAPI.rejectWithValue({ ...res, statusKey, body });
  }
);

export const updatePdpStatus = createAsyncThunk(
  'optimizationLog/updatePdpStatus',
  async (body, { rejectWithValue }) => {
    try {
      const response = await updatePdpStatusAPI(body);
      if (!response.isSuccessful) {
        return rejectWithValue(response);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createNewSampleJob = createAsyncThunk(
  'optimizationLog/createNewSampleJob',
  async ({ shopId }, { rejectWithValue }) => {
    try {
      const response = await newSampleJobAPI({ shopId });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const rerunSamples = createAsyncThunk(
  'optimizationLog/rerunSamples',
  async (body, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await rerunSamplesAPI(body);
    if (!isSuccessful) {
      handleRowLimitException({
        statusKey,
        dispatch: thunkAPI.dispatch,
        showSnack: false,
      });
      return thunkAPI.rejectWithValue({ ...data, statusKey });
    }
    const { pollData, pollStatusKey, isPollSuccessful } = await pollRequest({
      params: { id: data?.cache_id },
      request: getRerunSampleAPI,
    });
    if (!isPollSuccessful) {
      handleRowLimitException({
        statusKey: pollStatusKey,
        dispatch: thunkAPI.dispatch,
      });
      return thunkAPI.rejectWithValue({
        data: pollData,
        statusKey: pollStatusKey,
      });
    }
    return {
      data: pollData,
      isSuccessful: isPollSuccessful,
      statusKey: pollStatusKey,
      singular: body?.singular,
    };
  }
);

export const getSampleApprovals = createAsyncThunk(
  'optimizationLog/getSampleApprovals',
  async (body, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await getSampleApprovalsAPI(body);
    if (!isSuccessful) {
      handleRowLimitException({ statusKey });
      return thunkAPI.rejectWithValue({ ...data, statusKey });
    }
    return { data, currentPage: body.page };
  }
);

const optimizationLogSlice = createSlice({
  name: 'optimizationLog',
  initialState,
  reducers: {
    setSelectedProduct: (state, action) => {
      state.selectedProduct = action.payload;
    },
    setSelectedProductToCompare: (state, action) => {
      state.selectedProductToCompare = action.payload;
    },
    cleanOtherRoundsOptions: (state) => {
      state.otherRoundsOptions = {};
    },
    cleanAll: (state) => {
      Object.assign(state, initialState);
    },
    setNeedsApprovalItemsWithoutStatus: (state, action) => {
      state.needsApproval.itemsWithoutStatus = action.payload || [];
    },
    setApprovedItem: (state, action) => {
      const id = action.payload;
      const allItems = [
        ...(state.needsApproval.approvedItems ?? []),
        ...(state.needsApproval.itemsWithoutStatus ?? []),
        ...(state.needsApproval.rejectedItems ?? []),
      ];
      const item = allItems.find((item) => item.id === id);
      const index = state.needsApproval.approvedItems.findIndex(
        (item) => item.id === id
      );
      if (index !== -1) {
        state.needsApproval.approvedItems.splice(index, 1);
      } else if (item) {
        state.needsApproval.approvedItems.push(item);
      }
      const itemWithoutStatusIndex =
        state.needsApproval.itemsWithoutStatus.findIndex(
          (item) => item.id === id
        );
      if (itemWithoutStatusIndex !== -1) {
        state.needsApproval.itemsWithoutStatus.splice(
          itemWithoutStatusIndex,
          1
        );
      } else if (item) {
        state.needsApproval.itemsWithoutStatus.push(item);
      }
      state.needsApproval.pagination.pending.total -= 1;
      state.needsApproval.pagination.approved.total += 1;
    },
    setRejectedItem: (state, action) => {
      const id = action.payload;
      const allItems = [
        ...(state.needsApproval.approvedItems ?? []),
        ...(state.needsApproval.itemsWithoutStatus ?? []),
        ...(state.needsApproval.rejectedItems ?? []),
      ];
      const item = allItems.find((item) => item.id === id);
      const index = state.needsApproval.rejectedItems.findIndex(
        (item) => item.id === id
      );
      if (index !== -1) {
        state.needsApproval.rejectedItems.splice(index, 1);
      } else if (item) {
        state.needsApproval.rejectedItems.push(item);
      }
      const itemWithoutStatusIndex =
        state.needsApproval.itemsWithoutStatus.findIndex(
          (item) => item.id === id
        );
      if (itemWithoutStatusIndex !== -1) {
        state.needsApproval.itemsWithoutStatus.splice(
          itemWithoutStatusIndex,
          1
        );
      } else if (item) {
        state.needsApproval.itemsWithoutStatus.push(item);
      }
      state.needsApproval.pagination.pending.total -= 1;
      state.needsApproval.pagination.rejected.total += 1;
    },
    setItemsWithoutState: (state, action) => {
      const id = action.payload;
      const allItems = [
        ...(state.needsApproval.approvedItems ?? []),
        ...(state.needsApproval.itemsWithoutStatus ?? []),
        ...(state.needsApproval.rejectedItems ?? []),
      ];
      const item = allItems.find((item) => item.id === id);
      const index = state.needsApproval.itemsWithoutStatus.findIndex(
        (item) => item.id === id
      );
      if (index !== -1) {
        state.needsApproval.itemsWithoutStatus.splice(index, 1);
      } else if (item) {
        state.needsApproval.itemsWithoutStatus.push(item);
      }
      const approvedIndex = state.needsApproval.approvedItems.findIndex(
        (item) => item.id === id
      );
      if (approvedIndex !== -1) {
        state.needsApproval.approvedItems.splice(approvedIndex, 1);
        state.needsApproval.pagination.approved.total -= 1;
      }
      const rejectedIndex = state.needsApproval.rejectedItems.findIndex(
        (item) => item.id === id
      );
      if (rejectedIndex !== -1) {
        state.needsApproval.rejectedItems.splice(rejectedIndex, 1);
        state.needsApproval.pagination.rejected.total -= 1;
      }
      state.needsApproval.pagination.pending.total += 1;
    },
    resetNeedsApproval: (state) => {
      state.needsApproval.itemsWithoutStatus = [];
      state.needsApproval.approvedItems = [];
      state.needsApproval.rejectedItems = [];
    },
    setAutoApproval: (state, action) => {
      state.needsApproval.autoApproval = action.payload;
    },
    setCreatingLatestJob: (state, action) => {
      state.needsApproval.creatingLatestJob = action.payload;
    },
    setDontAskAgainPDP: (state, action) => {
      state.dontAskAgainPDP = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOptimizationHistoryLog.pending, (state, action) => {
        state.loadingOptimizationHistoryLog =
          action.meta.arg.filterParams.page <= 1;
        state.loadingOptimizationHistoryLogPagination =
          action.meta.arg.filterParams.page > 1;
      })
      .addCase(fetchOptimizationHistoryLog.fulfilled, (state, action) => {
        state.loadingOptimizationHistoryLog = false;
        state.loadingOptimizationHistoryLogPagination = false;
        let list = action.payload.data;
        if (!Array.isArray(list)) {
          list = [];
        }

        if (action.payload.current_page > 1) {
          list = [...state.optimizationHistoryLog, ...list];
        }
        state.optimizationHistoryLog = list.filter(
          (value, index, self) =>
            index ===
            self.findIndex((t) => t.autopilot_id === value.autopilot_id)
        );
        state.optimizationLogPagination = {
          currentPage: action.payload.current_page,
          hasMoreResults: action.payload.data.length === 20,
        };
      })
      .addCase(fetchOptimizationHistoryLog.rejected, (state) => {
        state.loadingOptimizationHistoryLog = false;
        state.loadingOptimizationHistoryLogPagination = false;
      })
      .addCase(getOptimizationLogStatisticsCards.pending, (state) => {
        state.loadingOptimizationLogStatisticsCards = true;
      })
      .addCase(getOptimizationLogStatisticsCards.rejected, (state) => {
        state.loadingOptimizationLogStatisticsCards = false;
      })
      .addCase(getOptimizationLogStatisticsCards.fulfilled, (state, action) => {
        state.loadingOptimizationLogStatisticsCards = false;
        const { id, ...data } = action.payload;
        state.optimizeLogsCards = {
          ...state.optimizeLogsCards,
          data: {
            ...state.optimizeLogsCards.data,
            [id]: {
              ...state.optimizeLogsCards.data?.[id],
              cards: data,
            },
          },
        };
      })
      .addCase(fetchProductDetails.pending, (state) => {
        state.loadingProductDetails = true;
      })
      .addCase(fetchProductDetails.fulfilled, (state, action) => {
        state.loadingProductDetails = false;
        const { isFromCompared = false, ...data } = action.payload;
        state.productsDetails = {
          ...state.productsDetails,
          [data.id]: { ...state.productsDetails[data.id], content: data },
        };

        if (!isFromCompared) {
          state.selectedProduct = {
            autopilot_super_row_id: data.id,
            product_id: data.product,
            product_name: data.product_name,
          };
        }

        if (isFromCompared) {
          state.selectedProductToCompare = {
            ...data,
            autopilot_super_row_id: data.id,
            product_id: data.product,
            product_name: data.product_name,
          };
        }
      })
      .addCase(fetchProductDetails.rejected, (state) => {
        state.loadingProductMetrics = false;
      })
      .addCase(fetchProductMetrics.pending, (state) => {
        state.loadingProductMetrics = true;
      })
      .addCase(fetchProductMetrics.fulfilled, (state, action) => {
        state.loadingProductMetrics = false;
        const { id, ...data } = action.payload;
        state.productsDetails = {
          ...state.productsDetails,
          [id]: { ...state.productsDetails[id], metrics: data },
        };
      })
      .addCase(fetchProductMetrics.rejected, (state) => {
        state.loadingProductMetrics = false;
      })
      .addCase(fetchOtherRoundsOptions.pending, (state) => {
        state.loadingOtherRoundsOptions = true;
      })
      .addCase(fetchOtherRoundsOptions.fulfilled, (state, action) => {
        state.loadingOtherRoundsOptions = false;
        state.otherRoundsPagination = {
          currentPage: action.meta.arg.filterParams.page,
          hasMore: action.payload.length >= 1,
        };
        const newOptions = action.payload?.reduce((acc, item) => {
          acc[item.row_id] = {
            label: formatDateByMonthAndDay(item.created_at, 'MMM D, YYYY'),
            value: item.row_id,
          };
          return acc;
        }, {});

        if (action.meta.arg.filterParams.page > 1) {
          state.otherRoundsOptions = {
            ...state.otherRoundsOptions,
            ...newOptions,
          };
        } else {
          state.otherRoundsOptions = newOptions;
        }
      })
      .addCase(fetchOtherRoundsOptions.rejected, (state) => {
        state.loadingOtherRoundsOptions = false;
      })
      .addCase(restoreSuperRow.pending, (state) => {
        state.loadingRestoreSuperRow = true;
      })
      .addCase(restoreSuperRow.fulfilled, (state, action) => {
        state.loadingRestoreSuperRow = false;
        const data = action.payload;

        state.productsDetails = {
          ...state.productsDetails,
          [data.id]: { ...state.productsDetails[data.id], content: data },
        };

        nomenclatureSnack({
          type: 'success',
          message: action?.payload?.statusKey,
        });
      })
      .addCase(restoreSuperRow.rejected, (state, action) => {
        state.loadingRestoreSuperRow = false;
        nomenclatureSnack({
          type: 'error',
          message: action?.payload?.statusKey,
        });
      })
      .addCase(getLatestReviewingJob.pending, (state) => {
        state.loadingLatestReviewingJob = true;
      })
      .addCase(getLatestReviewingJob.fulfilled, (state, action) => {
        state.loadingLatestReviewingJob = false;
        state.needsApproval.hasReviewingJobEnded = false;
        state.needsApproval.creatingLatestJob = false;
        const { job = null } = action.payload ?? {};
        state.needsApproval.job = job;
      })
      .addCase(getLatestReviewingJob.rejected, (state) => {
        state.loadingLatestReviewingJob = false;
        state.needsApproval.creatingLatestJob = true;
      })
      .addCase(getLatestReviewingJobRows.pending, (state, action) => {
        const status = action.meta.arg?.feedbackStatus?.toLowerCase();
        if (status) state.needsApproval.pagination[status]['loading'] = true;
      })
      .addCase(getLatestReviewingJobRows.fulfilled, (state, action) => {
        const status = action.payload?.feedbackStatus?.toLowerCase();
        if (status) {
          state.needsApproval.pagination[status] = {
            ...state.needsApproval.pagination[status],
            loading: false,
            currentPage: action.payload.page,
            hasMore: action.payload.rowsResponse.rows.length >= 20,
            total: action.payload.rowsResponse.count,
          };
          state.needsApproval.allItems = [
            ...state.needsApproval.allItems,
            ...action.payload.rowsResponse.rows,
          ];

          const addItems = (items, newItems) => {
            let array = [...newItems];
            if (action.payload.page > 1) array = [...items, ...newItems];
            return [...new Map(array.map((item) => [item.id, item])).values()];
          };
          switch (status) {
            case 'pending':
              state.needsApproval.itemsWithoutStatus = addItems(
                state.needsApproval.itemsWithoutStatus,
                action.payload.rowsResponse.rows
              );
              break;

            case 'approved':
              state.needsApproval.approvedItems = addItems(
                state.needsApproval.approvedItems,
                action.payload.rowsResponse.rows
              );
              break;

            case 'rejected':
              state.needsApproval.rejectedItems = addItems(
                state.needsApproval.rejectedItems,
                action.payload.rowsResponse.rows
              );
              break;
          }
        }
      })
      .addCase(getLatestReviewingJobRows.rejected, (state, action) => {
        const status = action.payload.body?.feedbackStatus?.toLowerCase();
        if (status) state.needsApproval.pagination[status]['loading'] = false;
      })
      .addCase(updatePdpStatus.pending, (state) => {
        state.loadingUpdatePdpStatus = true;
      })
      .addCase(updatePdpStatus.fulfilled, (state, action) => {
        state.loadingUpdatePdpStatus = false;
        state.needsApproval.hasReviewingJobEnded = action.payload?.is_fully_up;
      })
      .addCase(updatePdpStatus.rejected, (state, action) => {
        state.loadingUpdatePdpStatus = false;
        nomenclatureSnack({
          type: 'error',
          message: action?.payload?.statusKey,
        });
      })
      .addCase(createNewSampleJob.pending, (state) => {
        state.loadingNewSampleJob = true;
      })
      .addCase(createNewSampleJob.fulfilled, (state, action) => {
        state.loadingNewSampleJob = false;
        state.needsApproval.creatingLatestJob =
          action.payload?.starting_sample_jobs;
      })
      .addCase(createNewSampleJob.rejected, (state, action) => {
        state.loadingNewSampleJob = false;
        nomenclatureSnack({
          type: 'error',
          message: action?.payload?.statusKey,
        });
      })
      .addCase(rerunSamples.pending, (state) => {
        state.loadingSamplesRerun = true;
      })
      .addCase(rerunSamples.fulfilled, (state, action) => {
        state.loadingSamplesRerun = false;

        if (action.payload?.singular) {
          const updatedRow = action.payload.data[0];
          const updatedRows = [
            ...current(state.needsApproval.itemsWithoutStatus).filter(
              (item) => item.id !== updatedRow.id
            ),
            { ...updatedRow, isNew: true },
          ];
          state.needsApproval.itemsWithoutStatus = updatedRows;
        } else {
          state.needsApproval.itemsWithoutStatus = [
            ...current(state.needsApproval.itemsWithoutStatus),
            ...action.payload.data,
          ];
          const totalRegenerated = action?.payload?.data?.length ?? 0;
          state.needsApproval.pagination.pending.total += totalRegenerated;
          state.needsApproval.pagination.rejected.total -= totalRegenerated;
          state.needsApproval.rejectedItems = [];
        }

        nomenclatureSnack({
          type: 'success',
          message: action.payload.statusKey,
        });
      })
      .addCase(rerunSamples.rejected, (state, action) => {
        nomenclatureSnack({
          type: 'error',
          message: action.payload?.statusKey,
        });
        state.loadingSamplesRerun = false;
      })
      .addCase(getSampleApprovals.pending, (state) => {
        state.loadingSampleApprovals = true;
      })
      .addCase(getSampleApprovals.fulfilled, (state, action) => {
        if (current(state.sampleApprovals).length) {
          state.sampleApprovals = [
            ...current(state.sampleApprovals),
            ...action.payload.data.sample_pdps,
          ];
        } else state.sampleApprovals = action.payload.data.sample_pdps;
        state.sampleApprovalsPagination.total = action.payload.data.count;
        state.sampleApprovalsPagination.currentPage =
          action.payload.currentPage;
        state.loadingSampleApprovals = false;
      })
      .addCase(getSampleApprovals.rejected, (state) => {
        state.loadingSampleApprovals = false;
      });
  },
});

export const selectLoadingOptimizationHistoryLog = (state) =>
  state.optimizationLog.loadingOptimizationHistoryLog;
export const selectLoadingLoadingLatestReviewingJob = (state) =>
  state.optimizationLog.loadingLatestReviewingJob;
export const selectOptimizationHistoryLog = (state) =>
  state.optimizationLog.optimizationHistoryLog;
export const selectLoadingProductDetails = (state) =>
  state.optimizationLog.loadingProductDetails;
export const selectProductsDetails = (state) =>
  state.optimizationLog.productsDetails;
export const selectLoadingOtherRoundsOptions = (state) =>
  state.optimizationLog.loadingOtherRoundsOptions;
export const selectOtherRoundsOptions = (state) =>
  state.optimizationLog.otherRoundsOptions;
export const selectSelectedProduct = (state) =>
  state.optimizationLog.selectedProduct;
export const selectOptimizationLogPagination = (state) =>
  state.optimizationLog.optimizationLogPagination;
export const selectOptimizeLogsById = (state, id) =>
  state.optimizationLog.optimizeLogsCards.data?.[id];
export const selectLoadingOptimizationLogCards = (state) =>
  state.optimizationLog.loadingOptimizationLogStatisticsCards;
export const selectSelectedProductToCompare = (state) =>
  state.optimizationLog.selectedProductToCompare;
export const selectOtherRoundsOptionsPagination = (state) =>
  state.optimizationLog.otherRoundsPagination;
export const selectProductDetailById = (state, id) =>
  state.optimizationLog.productsDetails?.[id];
export const selectLoadingRestoreSuperRow = (state) =>
  state.optimizationLog.loadingRestoreSuperRow;
export const selectNeedsApproval = (state) =>
  state.optimizationLog.needsApproval;
export const selectNeedsApprovalApproved = (state) =>
  state.optimizationLog.needsApproval.pagination.approved;
export const selectNeedsApprovalPending = (state) =>
  state.optimizationLog.needsApproval.pagination.pending;
export const selectNeedsApprovalRejected = (state) =>
  state.optimizationLog.needsApproval.pagination.rejected;
export const selectLoadingSamplesRerun = (state) =>
  state.optimizationLog.loadingSamplesRerun;
export const selectSampleApprovals = (state) =>
  state.optimizationLog.sampleApprovals;
export const selectLoadingSampleApprovals = (state) =>
  state.optimizationLog.loadingSampleApprovals;
export const selectSamplesApprovalsPagination = (state) =>
  state.optimizationLog.sampleApprovalsPagination;

export default optimizationLogSlice.reducer;
export const {
  setSelectedProduct,
  setSelectedProductToCompare,
  cleanOtherRoundsOptions,
  cleanAll,
  setNeedsApprovalItemsWithoutStatus,
  setApprovedItem,
  setRejectedItem,
  resetNeedsApproval,
  setItemsWithoutState,
  setAutoApproval,
  setCreatingLatestJob,
  setDontAskAgainPDP,
} = optimizationLogSlice.actions;
