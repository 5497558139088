import { makeRequest } from '@api/client';

const updateAutopilotJobSettingsAPI = async ({ body, autopilotId }) => {
  return await makeRequest(`autopilot/${autopilotId}/`, {
    method: 'PATCH',
    body,
  });
};

const getAutopilotJobSettingsAPI = async (autopilotId) => {
  return await makeRequest(`autopilot/${autopilotId}/`, {
    method: 'GET',
  });
};

const getAutopilotJobsAPI = async (params) => {
  return await makeRequest(
    `autopilot-job/${params?.shop_id ? `?shop_id=${params?.shop_id}` : ''}`,
    {
      method: 'GET',
    }
  );
};

const getAutopilotJobAPI = async (id) => {
  return await makeRequest(`autopilot-job/${id}/`, {
    method: 'GET',
  });
};

const updateAutopilotJobAPI = async ({ id, body }) => {
  return await makeRequest(`autopilot-job/${id}/`, {
    method: 'PATCH',
    body,
  });
};

const getAutopilotBatchRowsAPI = async (body) => {
  return await makeRequest(
    `autopilot-rows/${body?.id}?page=${body?.page || 1}&page_size=${
      body?.pageSize || 10
    }${body?.feedbackStatus ? `&feedback_status=${body?.feedbackStatus}` : ''}`,
    {
      method: 'GET',
    }
  );
};

const rerunAutopilotBatchRowAPI = async ({ body, rowId }) => {
  return await makeRequest(`autopilot-rerun-post/${rowId}`, {
    method: 'POST',
    body,
  });
};

const getRerunAutopilotBatchRowAPI = async ({ id }) => {
  return await makeRequest(`autopilot-rerun-get/${id}`, {
    method: 'GET',
  });
};

const pushAutopilotJobToCmsAPI = async (body) => {
  return await makeRequest(`autopilot-push`, {
    method: 'POST',
    body,
  });
};

const updateAutopilotRowAPI = async ({ body, rowId }) => {
  return await makeRequest(`autopilot-rows/${rowId}`, {
    method: 'PATCH',
    body,
  });
};

const getAutopilotJobCsvAPI = async (autopilotJobId) => {
  return await makeRequest(`autopilot-job-csv/${autopilotJobId}`, {
    method: 'GET',
  });
};

const reoptimizeAutopilotBatchRowAPI = async ({ body, rowId }) => {
  return await makeRequest(`autopilot-reoptimize-post/${rowId}`, {
    method: 'POST',
    body,
  });
};

const getReoptimizedAutopilotBatchRowAPI = async ({ id }) => {
  return await makeRequest(`autopilot-reoptimize-get/${id}`, {
    method: 'GET',
  });
};

const createManualJobAPI = async (body) => {
  return await makeRequest('optimize', {
    method: 'POST',
    body,
  });
};

const testGoogleIndexingAPI = async ({ autopilotId }) => {
  return await makeRequest(
    `google-search-console/test-indexing/${autopilotId}/`,
    {
      method: 'POST',
    }
  );
};

const autopilotRescoreAPI = async (body) => {
  return await makeRequest(`autopilot-rescore-post/${body?.id}`, {
    method: 'POST',
  });
};

const getAautopilotRescoreAPI = async (body) => {
  return await makeRequest(`autopilot-rescore-get/${body?.id}`, {
    method: 'GET',
  });
};

const getOnboardingJobDataAPI = async (body) => {
  return await makeRequest(`onboarding-job/${body?.id}`, {
    method: 'GET',
  });
};

const postOnboardingReRunAPI = async (body) => {
  return await makeRequest(`onboarding-rerun-post/${body?.id}`, {
    method: 'POST',
    body,
  });
};

const getOnboardingReRunAPI = async (body) => {
  return await makeRequest(`onboarding-rerun-get/${body?.id}`, {
    method: 'GET',
  });
};

const autopilotSampleRerunAPI = async (body) => {
  return await makeRequest(`sample-rerun-post/${body?.id}`, {
    method: 'POST',
    body,
  });
};

export {
  updateAutopilotJobSettingsAPI,
  getAutopilotJobSettingsAPI,
  getAutopilotJobsAPI,
  updateAutopilotJobAPI,
  getAutopilotJobCsvAPI,
  updateAutopilotRowAPI,
  getAutopilotJobAPI,
  getAutopilotBatchRowsAPI,
  pushAutopilotJobToCmsAPI,
  rerunAutopilotBatchRowAPI,
  getRerunAutopilotBatchRowAPI,
  reoptimizeAutopilotBatchRowAPI,
  getReoptimizedAutopilotBatchRowAPI,
  testGoogleIndexingAPI,
  createManualJobAPI,
  autopilotRescoreAPI,
  getAautopilotRescoreAPI,
  getOnboardingJobDataAPI,
  postOnboardingReRunAPI,
  getOnboardingReRunAPI,
  autopilotSampleRerunAPI,
};
